@use '../../styles/mixins' as mixins;


.case-studies-block {

	@include mixins.sectionStyles;

	.info {
		display: flex;
		align-items: center;
		gap: var(--wp--preset--spacing--40);
		justify-content: space-between;
		flex-wrap: wrap;

		.filters {
			display: flex;
			align-items: center;
			gap: var(--wp--preset--spacing--40);
			flex-wrap: wrap;

			select {
				background-color: var(--wp--preset--color--bnpp-green-tint-10);
			}
		}
	}

	button {
		margin-top: var(--wp--preset--spacing--40);
	}

	&.dark {

		@include mixins.SVGDarkBg;

		.filters {

			select {
				
				option {
					color: var(--wp--preset--color--bnpp-black)!important;
				}
			}
		}

	}
	
}